import React from "react";
import "./tabs.css";
interface selectExpModalProps {
  textURL: string;
  iframeURL: string | undefined;
  languageEng?: string;
  languageFra?: string;
  setTourismUrl?: Function;
}
const ChooseExperience: React.FC<selectExpModalProps> = ({
  textURL,
  iframeURL,
  languageEng,
  languageFra,
}: selectExpModalProps) => {
  return (
    <div className="tab-wrappper-content">
      <div>
        <img src={textURL} alt="header-content-text" />{" "}
        <div style={{ padding: "10px 0", fontSize: "16px" }}>
          <span
            style={{
              paddingRight: "8px",
              fontWeight: "300",
              cursor: "pointer",
            }}
          >
            <a
              style={{ color: "#000" }}
              href="https://experience-guide-staging.becknprotocol.io/cityOfLight"
              target="_self"
              rel="noreferrer"
            >
              {languageEng}
            </a>
          </span>
          |
          <span
            style={{
              paddingLeft: "8px",
              cursor: "pointer",
              pointerEvents: "none",
            }}
          >
            <a
              style={{ color: "#000" }}
              href="https://experience-guide-french-infra.becknprotocol.io/cityOfLight"
              target="_self"
              rel="noreferrer"
            >
              {languageFra}
            </a>
          </span>
        </div>
      </div>

      <div className="smartphone-wrapper">
        <div className="smartphone">
          <div className="content">
            <iframe
              className="ChooseExpIframe"
              allow="clipboard-read; clipboard-write; geolocation"
              src={iframeURL}
              frameBorder="0"
              allowFullScreen
              width={"375px"}
              height={"667px"}
              style={{ borderRadius: "36px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseExperience;
